<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-row>
          <b-col>
            <v-select
              id="id_com_realty_project"
              v-model="id_com_realty_project"
              :options="projects"
              label="title"
              :reduce="project => project.id"
              placeholder="Proje Seçiniz"
            />
          </b-col>
          <b-col cols="auto">
            <b-dropdown
              variant="warning"
              text="Fiyat Güncelleme"
              class="mr-1"
            >
              <b-dropdown-item
                v-for="project in projects"
                :key="project.id"
                :to="'/realty/stocks/multiple/' + project.id"
              >
                {{ project.title }}
              </b-dropdown-item>
            </b-dropdown>
            <b-button
              to="/realty/stocks/add"
              variant="primary"
            >
              <FeatherIcon icon="PlusIcon" />
              Ekle
            </b-button>
          </b-col>
        </b-row>
        <b-table
          class="mt-1"
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          fixed
        >
          <template #cell(project)="data">
            <div>
              {{ data.item.project }}
            </div>
            <small class="text-primary">{{ data.item.type }}</small>
          </template>
          <template #cell(price)="data">
            {{ data.item.price | toCurrency }} ₺
          </template>
          <template #cell(block)="data">
            <div>
              {{ data.item.block }}
            </div>
            <div class="font-small-2 text-warning">
              Kat: {{ data.item.floor }}
            </div>
            <div class="font-small-2 text-muted">
              No: {{ data.item.number }}
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              :to="'/realty/stocks/edit/' + data.item.id"
              variant="outline-primary"
              size="sm"
              class="mr-1"
            >
              Güncelle
            </b-button>
            <b-button
              variant="outline-danger"
              size="sm"
              @click="deleteData(data.item.id)"
            >
              Sil
            </b-button>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      id_com_realty_project: null,
      currentPage: 1,
      perPage: 10,
      search: '',
      fields: [
        {
          key: 'project',
          label: 'Proje Adı',
        },
        {
          key: 'block',
          label: 'Blok',
        },
        {
          key: 'price',
          label: 'Liste Fiyatı',
          thStyle: { width: '220px' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '220px' },
        },
      ],
      dataQuery: {
        select: [
          'com_realty_number.id AS id',
          'com_realty_project.title AS project',
          'com_realty_type.title AS type',
          'com_realty_block.title AS block',
          'com_realty_number.floor AS floor',
          'com_realty_number.number AS number',
          'com_realty_number.price AS price',
        ],
        order_by: ['com_realty_block.id_com_realty_project', 'ASC'],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    projects() {
      return this.$store.getters['realtyProjects/getRealty_sale_projects']
    },
    dataList() {
      return this.$store.getters['realtyNumbers/getRealty_sale_numbers']
    },
    dataCount() {
      return this.$store.getters['realtyNumbers/getRealty_sale_numbersCount']
    },
    saveStatus() {
      return this.$store.getters['realtyNumbers/getRealty_sale_numberSaveStatus']
    },
  },
  watch: {
    id_com_realty_project(val) {
      console.log(val)
      this.dataQuery.where = {
        'com_realty_number.id_com_realty_project': val,
      }
      this.getDataList()
    },
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
    saveStatus(val) {
      if (val.status === true) {
        this.getDataList()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getProjects()
  },
  methods: {
    getProjects() {
      this.$store.dispatch('realtyProjects/realty_sale_projectsList', {
        select: [
          'com_realty_project.id AS id',
          'com_realty_project.title AS title',
        ],
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('realtyNumbers/realty_sale_numbersList', this.dataQuery)
    },
    deleteData(id) {
      this.$store.dispatch('realtyNumbers/realty_sale_numberDelete', id)
    },
  },
}
</script>
